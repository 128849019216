$base-color-background: #F4F5F4;
$base-color-white: #fff;
$base-color-red:#CA3935;
$base-color-font:#666666;
$base-color-black: #515a6e;
$base-color-blue: #1890ff;
$base-color-golden:rgba(199, 138, 41, 1);
$base-color-grey: rgba(0, 0, 0, 0.65);
$base-color-bg:#efeade;
//默认动画
$base-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),border 0s,color 0.1s, font-size 0s;
$base-transition-all:all 0.5s ease;
//默认动画长
$base-transition-time: 0.3s;
//边框颜色
$base-border-color: #dcdfe6;
//输入框高度
$base-input-height: 32px;
//默认margin
$base-margin: 20px;
//默认padding
$base-padding: 20px;

//顶部nav-bar的高度
$base-nav-height: 70px;
//app-main的高度
$base-keep-alive-height: calc(
  100vh - #{$base-nav-height} - 236px
);
//菜单背景色
$base-nav-background: #fff;
//基础字体
$font20:1.42rem;
$font22:1.57rem;
$font30:2.14rem;
$font35:2.5rem;
$font26:1.85rem;
$font15:1.07rem;
$font18:1.29rem;
$font10:0.71rem;
$px60:4.28rem;
$px37:2.64rem;
$px50:3.57rem;
$px17:1.21rem;
$px48:3.43rem;
//菜单li标签的高度
$base-menu-item-height: 50px;
//菜单背景色
$base-menu-background: #fff;
//菜单文字颜色
$base-menu-color: #000;
//菜单选中文字颜色
$base-menu-color-active: #c41230;
//菜单选中背景色
$base-menu-background-active: $base-color-red;
:export {
  // 菜单文字颜色变量导出
  menu-color: $base-menu-color;
  // 菜单选中文字颜色变量导出
  menu-color-active: $base-menu-color-active;
  // 菜单背景色变量导出
  menu-background: $base-menu-background;
}
