:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.video-box {
  width: 100%;
  height: 100%;
}
.video-box .vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
  border: none;
}
.video-box .vjs-custom-skin > .video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  font-size: 3rem;
}

.video-item-list .video-item-item {
  margin-top: 10px;
  text-align: left;
  color: #333333;
  border: 1px solid #ebebeb;
  padding: 10px;
  cursor: pointer;
}
.video-item-list .video-item-item:first-child {
  margin-top: 0px;
}
.video-item-list .video-item-item img {
  width: 100%;
}
.video-item-list .video-item-item .title {
  font-weight: bold;
  margin: 10px 0px;
}
.video-item-list .video-item-item .txt {
  color: #666666;
}

.news-item {
  padding-top: 30px;
}
.news-item .left img {
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.news-item .right {
  color: #2d2d2d;
  text-align: left;
  height: 29.15vw;
  overflow: auto;
}
.news-item .right .newtext {
  letter-spacing: 3px;
}
.news-item .right .title {
  margin-bottom: 20px;
  font-size: 1.42rem;
  font-weight: bold;
}

.video-item {
  background: #fff;
  padding: 15px 5px;
  margin-top: 15px;
  min-height: 400px;
}
.video-item .left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.video-item .left .title {
  text-align: left;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: rgb(28, 28, 30);
}
.video-item .left .title .txt {
  color: #fff;
  padding-left: 10px;
}
.video-item .left img {
  width: 100%;
}
.video-item .right {
  height: 45vw;
  overflow: auto;
}

.clear-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.index-container .home-top {
  margin-top: -50px;
}
.index-container .home-top .home-top-banners {
  position: relative;
  top: 0px;
}
.index-container .home-top .home-top-banners .swiper-out-wrapper-banner {
  overflow: hidden;
  margin: auto;
  max-height: 800px;
}
.index-container .home-top .home-top-banners .swiper-out-wrapper-banner .swiper-slide img {
  border-radius: 0 0 50% 50%/5%;
  width: 100%;
  object-fit: cover;
  max-height: 800px;
}
.index-container .home-content {
  position: relative;
}
.index-container .home-content .home-menu {
  width: 100%;
}
.index-container .home-content .home-menu .menu-list {
  margin: auto;
  width: 90%;
}
.index-container .home-content .home-menu .menu-list .menu-item {
  text-align: center;
  margin-top: 20px;
}
.index-container .home-content .home-menu .menu-list .menu-item > img {
  height: 180px;
  width: 180px;
}
.index-container .home-content .home-menu .menu-list .menu-item .title {
  font-size: 20px;
  margin-top: 10px;
}

.home-section {
  padding-top: 30px;
}
.home-section .home-show,
.home-section .home-item {
  width: 90%;
  margin: 0 auto;
}
.home-section .home-show .home-show-right,
.home-section .home-item .home-show-right {
  margin-top: 15px;
}
.home-section .home-show .yc-wrapper,
.home-section .home-item .yc-wrapper {
  padding: 20px 0px;
}
.home-section .home-show .des,
.home-section .home-item .des {
  text-align: left;
}
.home-section .home-show .des .des-txt,
.home-section .home-item .des .des-txt {
  line-height: 1.5;
}

.home-ep {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebjwhite1.jpg");
  padding-bottom: 30px;
}
.home-ep .swiper-out-wrapper {
  padding: 20px 0px;
}

.news-section {
  background-image: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebjwhite1.jpg");
  padding: 20px 0px;
}

.video-section {
  padding: 20px 0px;
}

.home-bg {
  background-color: #F4F5F4;
}